import { template as template_9600e94d64204ede8efff97654a27a34 } from "@ember/template-compiler";
const EmptyState = template_9600e94d64204ede8efff97654a27a34(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
