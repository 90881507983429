import { template as template_c84da49fbe1d4e2ab2daae3787988216 } from "@ember/template-compiler";
const FKLabel = template_c84da49fbe1d4e2ab2daae3787988216(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
